import React from "react";
import Navbar from "./components/navbar"
import Footer from "./components/footer"
import './App.css';
import {

} from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Navbar/>
      <Footer/>
      <br/>
      <br/>
    </div>
  );
}

export default App;
